<template>
  <h1 class="main-title"><b>Наследство</b><span>.Онлайн</span></h1>
  <h2 class="subtitle">Сервис, соблюдающий принцип независимости нотариуса <a href="https://наследство.онлайн/independence" target="_blank">подробнее...</a></h2>
</template>

<script>
export default {
    name: "HeaderComponent"
}
</script>

<style scoped>

  .main-title {
    font-size: 24px;
    color: #000000;
    text-align: center;
    margin-bottom: 5px;
  }

  .main-title>span {
    color: #00B0F0;
  }

  .subtitle {
    font-size: 21px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 0;
    margin-top: 0;
  }

  .subtitle>a {
    font-size: 17px;
    color: #00B0F0;
  }

</style>
