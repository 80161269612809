import HomeView from "../views/home/HomeView";
import AuthView from "../views/auth/AuthView";
import SignInView from "../views/auth/SignInView";
import SignUpView from "../views/auth/signUpView/SignUpView";
import VerifyEmailView from "../views/auth/VerifyEmailView";
import LostPassView from "../views/auth/LostPassView";
import ResetPasswordView from "../views/auth/ResetPasswordView";
import NotFoundView from "../views/NotFoundView";
import SettingView from "../views/setting/SettingView";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      auth: true
    },
  },
  {
    path: '/setting',
    name: 'setting',
    component: SettingView,
    meta: {
      auth: true,
      title: 'Настройки',
      breadcrumb: 'Настройки'
    }
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthView,
    redirect: '/auth/sign-in',
    children: [
      {
        path: 'sign-in',
        name: 'auth-sign-in',
        component: SignInView,
        meta: {
          auth: false
        }
      },
      {
        path: 'sign-up',
        name: 'sign-up',
        component: SignUpView,
        meta: {
          auth: false
        }
      },
      {
        path: 'verify-email',
        name: 'verify-email',
        component: VerifyEmailView,
        meta: {
          auth: false,
        }
      },
      {
        path: 'lost-pass',
        name: 'lost-pass',
        component: LostPassView,
        meta: {
          auth: false,
        }
      },
      {
        path: 'reset-password',
        name: 'reset-password',
        component: ResetPasswordView,
        meta: {
          auth: false,
        }
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: NotFoundView,
    meta: {
      title: '(404) Страница не найдена',
      breadcrumb: 'Страница не найдена'
    }
  },
];

export default routes
