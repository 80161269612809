import { getUserRequest } from "../../api/auth";
import { useCookies } from 'vue3-cookies';

const { cookies } = useCookies();

const state = () => ({
  user: null,
  isLoggedIn: cookies.isKey('token'),
  display: false,
  routeTo: null
});

const getters = {};

const actions = {
  getUser({ commit }) {
    return getUserRequest().then(response => {
      commit('setUser', response.data.user);
    });
  },
};

const mutations = {
  setUser (state, value) {
    state.user = value;
    state.isLoggedIn = true;
  },
  clearUser (state) {
    state.user = null;
    state.isLoggedIn = false;
  },
  showAuth (state, payload) {
    state.display = true;
    state.routeTo = payload.to;
  },
  hideAuth (state) {
    state.display = false;
    state.routeTo = null
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
