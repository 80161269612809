<template>
    <section>
      <input type="text" placeholder="Поиск по № дела" class="form-control" v-model="fields.notaryNumber" @keyup="getAll(1)"/>
      <input type="text" placeholder="По ФИО наследодателя " class="form-control" v-model="fields.notaryTestatorName" @keyup="getAll(1)"/>
    </section>
</template>

<script>
import { inject } from 'vue';
export default {
    name: "FilterComponent",
    props: {
        fields: {
            type: Object,
            required: true
        }
    },
    setup() {

        const getAll = inject('getAll');

        return {
            getAll
        }
    }
}
</script>

<style scoped>

  section {
    margin-bottom: 20px;
  }

  section>input {
    display: inline-block;
    margin-right: 10px;
    font-size: 1.5rem;
  }

  section>input:first-child {
    max-width: 200px;
  }

  section>input:last-child {
    max-width: 400px;
  }

</style>
