<template>
  <div>
    <span :class="['btn-group',route.path === '/setting'?'active':'']" title="Настройки">
      <router-link to="/setting">
        <font-awesome-icon icon="cog" />
      </router-link>
    </span>
    <span :class="['btn-group',route.path === '/'?'active':'']">
      <router-link to="/">
        <font-awesome-icon icon="user-circle" />
      </router-link>
      <a href="#" @click="handleLogoutSubmit">Выйти</a>
    </span>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useCookies } from 'vue3-cookies';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { logoutRequest } from "../../../../api/auth";
import { AUTH_CLEAR_USER, DEFAULT_SHOW_LOADER, DEFAULT_HIDE_LOADER } from "../../../../store/constants";

export default {
    name: "UserActionComponent",
    setup() {

        const { cookies } = useCookies();
        const store = useStore();
        const router = useRouter();
        const route = useRoute();

        const handleLogoutSubmit = (e) => {
            e.preventDefault();
            store.commit(DEFAULT_SHOW_LOADER);
            logoutRequest().then(() => {
                store.commit(DEFAULT_HIDE_LOADER);
                cookies.remove('token');
                store.commit(AUTH_CLEAR_USER);
                router.push('/auth/sign-in');
            });
        };

        return {
            handleLogoutSubmit,
            user: computed(() => store.state.auth.user),
            route
        }
    }
}
</script>

<style scoped>

  .btn-group svg {
    font-size: 24px;
    color: #4d5e66;
  }

  .btn-group>a {
    font-size: 24px;
    color: #1b1e21;
    margin-left: 5px;
  }

  .btn-group.active svg {
    color: #00B0F0;
  }

</style>
