<template>
    <section>
      <div class="top">
        Информационные материалы для нотариальных контор:
      </div>
      <div class="items">
        <div class="item">
          <div class="title">
            Объявление формата А4
          </div>
          <div class="image image1">
            <img :src="image1" />
          </div>
          <div class="link">
            <a href="#" @click="handleGetA4PdfClick">Скачать PDF файл объявления для печати</a>
          </div>
        </div>
        <div class="item">
          <div class="title">
            Объявление формата А5
          </div>
          <div class="image image2">
            <img :src="image2" />
          </div>
          <div class="link">
            <a href="#" @click="handleGetA5PdfClick">Скачать PDF файл объявления для печати</a>
          </div>
        </div>
        <div class="item">
          <div class="title">
            Визитка для раздачи
          </div>
          <div class="image image3">
            <img :src="image3" />
          </div>
          <div class="button">
            <button v-if="isShowGetCardVisitsButton" type="button" class="btn btn-primary" @click="handleGetCardVisits">Запросить комплект визиток</button>
            <template v-else-if="lastTimeCardVisits !== ''">
              <p>ЗАПРОС ОТПРАВЛЕН (ПОВТОРНО ЧЕРЕЗ {{ lastTimeCardVisits }} ДНЯ)</p>
            </template>
          </div>
        </div>
      </div>
      <loader v-if="loader"/>
    </section>
</template>

<script>
import { ref, onMounted } from 'vue';
import { createToaster } from '@meforma/vue-toaster';
import { getA4PdfRequest, getA5PdfRequest, getCartVisitsRequest, getSettingDataRequest } from "../../../api/notary";
import image1 from '@/assets/images/H-O-obyavl-A4.jpg';
import image2 from '@/assets/images/H-O-obyavl-A5.jpg';
import image3 from '@/assets/images/H-O-vizitka.jpg';
import Loader from "../../../components/common/Loader";
export default {
    name: "InformationComponent",
    components: {
        Loader
    },
    setup() {

        const loader = ref(false);
        const toaster = createToaster({ position: 'top' });
        const isShowGetCardVisitsButton = ref(false);
        const lastTimeCardVisits = ref('');

        const handleGetA4PdfClick = (e) => {
            e.preventDefault();
            loader.value = true;
            getA4PdfRequest().then(response => {
                loader.value = false;
                const data = response.data;
                const blob = new Blob([data],{ type: data.type });
                const url = window.URL.createObjectURL(blob, {
                    type: data.type
                });
                const link = window.document.createElement("a");
                link.href = url;
                link.download = 'H-O-obyavl-A4.pdf';
                link.target = '_blank';
                link.click();
                window.URL.revokeObjectURL(url);
            }).catch(err => console.log(err));
        };

        const handleGetA5PdfClick = (e) => {
            e.preventDefault();
            loader.value = true;
            getA5PdfRequest().then(response => {
                loader.value = false;
                const data = response.data;
                const blob = new Blob([data],{ type: data.type });
                const url = window.URL.createObjectURL(blob, {
                    type: data.type
                });
                const link = window.document.createElement("a");
                link.href = url;
                link.download = 'H-O-obyavl-A5.pdf';
                link.target = '_blank';
                link.click();
                window.URL.revokeObjectURL(url);
            }).catch(err => console.log(err));
        };

        const handleGetCardVisits = () => {
            loader.value = true;
            getCartVisitsRequest().then(response => {
                loader.value = false;
                if(response.data) {
                    isShowGetCardVisitsButton.value = false;
                    lastTimeCardVisits.value = '2';
                    toaster.success('Запрос был успешно отправлен. Ожидайте ответа');
                }
            }).catch(err => console.log(err));
        };

        const getData = () => {
            loader.value = true;
            getSettingDataRequest().then(response => {
                loader.value = false;
                if(response.data) {
                    const data = response.data;
                    isShowGetCardVisitsButton.value = data.isExpiredTimeSendGetVisits;
                    lastTimeCardVisits.value = data.last_time_send_get_visits;
                }
            }).catch(err => console.log(err));
        };

        onMounted(() => {
            getData();
        });

        return {
            loader,
            image1,
            image2,
            image3,
            handleGetA4PdfClick,
            handleGetA5PdfClick,
            handleGetCardVisits,
            isShowGetCardVisitsButton,
            lastTimeCardVisits
        }
    }
}
</script>

<style scoped>

  section {
    margin-top: 20px;
    position: relative;
  }

  .top {
    text-align: center;
    color: #000000;
    font-size: 37px;
    font-weight: 500;
    border-top: 2px solid #FFF;
    border-bottom: 2px solid #FFF;
    padding: 10px 0;
  }

  .items {
    display: flex;
  }

  .items .item {
    width: 33.3%;
    border-left: 2px solid #FFF;
    border-bottom: 2px solid #FFF;
  }

  .items .item:last-child {
    border-right: 2px solid #FFF;
  }

  .item .title {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin: 15px 0;
  }

  .item .image {
    display: flex;
    width: 90%;
    margin: 0 auto;
    align-items: center;
    min-height: 230px;
  }

  .item .image>img {
    display: block;
  }

  .item .image2>img {
    width: 80%;
    margin: 0 auto;
  }

  .item .image3>img {
    width: 60%;
    margin: 0 auto;
  }

  .item .link,
  .item .button {
    text-align: center;
    margin: 20px 0;
  }

  .item .link>a {
    font-size: 16px;
    color: #1FB9F0;
    font-weight: 500;
    border-bottom: 1px solid #1FB9F0;
    text-decoration: none;
  }

  .item .link>a:hover {
    border-bottom: none;
  }

  .item .button>p {
    font-weight: 500;
    color: #005f96;
  }

  @media (max-width: 992px) {

    .top {
      font-size: 25px;
    }

    .items {
      display: block;
    }

    .items .item {
      width: 100%;
    }
  }

</style>
