import { createRouter, createWebHistory } from "vue-router";
import store from '../store';
import routes from "./routes";

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if(to.meta.auth === true && !store.state.auth.isLoggedIn) {
    next('/auth/sign-in');
  } else if(to.meta.auth === false && store.state.auth.isLoggedIn) {
    next('/');
  } else {
    next();
  }
});

export default router
