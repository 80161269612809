<template>
  <layout-default>
    <div>
      <p>Страница которую вы запрашиваете не существует</p>
    </div>
  </layout-default>
</template>

<script>
import LayoutDefault from "../layouts/LayoutDefault";
export default {
    name: "NotFoundView",
    components: {
        LayoutDefault
    }
}
</script>

<style scoped>

</style>
