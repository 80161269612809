import { createStore } from 'vuex';
import auth from "./modules/auth";

export default createStore({
  state: {
    loader: false
  },
  actions: {},
  mutations: {
    showLoader(state) {
      state.loader = true;
    },
    hideLoader(state) {
      state.loader = false;
    }
  },
  getters: {},
  modules: {
    auth: auth
  },
})
