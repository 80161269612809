<template>
  <router-view/>
</template>

<script>
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useCookies } from 'vue3-cookies';
import { AUTH_GET_USER } from "../store/constants";

export default {
  setup() {

      const store = useStore();
      const { cookies } = useCookies();

      onMounted(async () => {
          if (cookies.isKey('token')) {
              await store.dispatch(AUTH_GET_USER);
          }
      });
  }
}
</script>

<style>

  #app {
    height: 100%;
  }

</style>
