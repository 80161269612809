<template>
  <section class="top">
    <div class="row">
      <div class="col-sm-8">
        <h3><b>Нотариус:</b> {{ user.notary.name }} ({{ user.notary.region }})</h3>
      </div>
      <div class="col-sm-4">
        <div class="marketing">
          Сервис <b>НАСЛЕДСТВО.<span>ОНЛАЙН</span></b> - самая низкая цена на <b>оценку легкового авто</b> для наследства в РФ - <b>500 руб.</b>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
export default {
    name: "TopComponent",
    setup() {

        const store = useStore();

        return {
            user: computed(() => store.state.auth.user)
        }
    }
}
</script>

<style scoped>

  .top {
    padding-bottom: 20px;
    border-bottom: 1px dashed #CCC;
  }

  h3 {
    font-size: 24px;
    font-weight: normal;
  }

  .col-sm-4 {
    text-align: right;
  }

  .marketing {
    background-color: #DEEBF7;
    padding: 10px;
    border: 1px solid #41719C;
    border-radius: 15px;
    color: #595959;
    display: inline-block;
    text-align: left;
    width: 360px;
    line-height: 2em;
    font-size: 18px;
  }

  .marketing>span {
    color: #67A2D8;
  }

</style>
