<template>
  <layout-auth>
    <email-component/>
    <information-component/>
  </layout-auth>
</template>

<script>
import LayoutAuth from "../../layouts/LayoutAuth";
import EmailComponent from "./email/EmailComponent";
import InformationComponent from "./information/InformationComponent";
export default {
    name: "SettingView",
    components: {
        LayoutAuth,
        EmailComponent,
        InformationComponent
    }
}
</script>

<style scoped>

</style>
