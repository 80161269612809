<template>
    <section>
      <h2>Заказы оценки, направленные нотариусу ({{ orders.length }} шт.)</h2>
      <filter-component :fields="filter"/>
      <div v-if="orders.length" class="orders">
        <div class="items">
          <item-component v-for="order in orders" :key="order.id" :item="order"/>
        </div>
        <pagination v-if="pages.length" :items="pages" @action="getAll" />
      </div>
      <div v-else class="orders-empty">
        <p>Заказов не найдено</p>
      </div>
    </section>
</template>

<script>
import { onMounted, ref, provide } from 'vue';
import { getOrdersRequest } from "../../../api/notary";
import Pagination from "../../../components/common/Pagination";
import Loader from "../../../components/common/Loader";
import ItemComponent from "./item/ItemComponent";
import FilterComponent from "./filter/FilterComponent";

export default {
    name: "OrdersComponent",
    components: {
        Pagination,
        Loader,
        ItemComponent,
        FilterComponent
    },
    setup() {

        const orders = ref([]);
        const pages = ref([]);
        const filter = ref({
            page: 1,
            orderNumber: '',
            notaryNumber: '',
            notaryTestatorName: '',
        });

        const getAll = (page) => {
            if(page) {
                filter.value.page = page;
            }
            getOrdersRequest({
                page: filter.value.page,
                orderNumber: filter.value.orderNumber,
                notaryNumber: filter.value.notaryNumber,
                notaryTestatorName: filter.value.notaryTestatorName
            }).then(response => {
                const data = response.data;
                if(data) {
                    orders.value = data.items;
                    pages.value = data.pages;
                }
            }).catch(err => console.log(err));
        };

        onMounted(() => {
            getAll();
        });

        provide('getAll', getAll);

        return {
            orders,
            pages,
            getAll,
            filter
        }
    }
}
</script>

<style scoped>

  section {
    margin-top: 20px;
  }

  h2 {
    font-size: 24px !important;
    font-weight: 700;
  }

</style>
