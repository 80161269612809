<template>
  <section>
    <div class="row">
      <div class="col-sm-9">
        <span class="main-title"><b>НАСЛЕДСТВО</b><span><b>.ОНЛАЙН</b></span></span>
        <div class="subtitle">Сервис, соблюдающий принцип независимости нотариуса <a href="https://наследство.онлайн/independence" target="_blank">подробнее...</a></div>
      </div>
      <div class="col-sm-3">
        <user-action-component/>
      </div>
    </div>
  </section>
</template>

<script>
import UserActionComponent from "./userAction/UserActionComponent";
export default {
    name: "HeaderAuthComponent",
    components: {
        UserActionComponent
    }
}
</script>

<style scoped>

  section {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px dashed #CCC;
  }

  section span.main-title {
    font-size: 24px;
    color: #000000;
  }

  section span.main-title>span {
    color: #00B0F0;
  }

  .col-sm-3 {
    text-align: right;
    padding-top: 10px;
  }

  .subtitle {
    font-size: 21px;
    font-weight: 500;
    margin-top: 5px;
  }

  .subtitle>a {
    font-size: 17px;
    color: #00B0F0;
  }

</style>
