<template>
  <ul class="breadcrumb" v-if="$breadcrumbs.value.length">
    <li v-if="mainUrl" class="breadcrumb-item">
      <router-link :to="mainUrl">Главная</router-link>
    </li>
    <li class="breadcrumb-item" v-for="(crumb, key) in $breadcrumbs.value" :key="key">
      <router-link :to="crumb._path==='*'?'not-found':crumb._path">{{ crumb.label }}</router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  props: {
    mainUrl: {
        type: String,
        required: false,
        default: null
    }
  }
}
</script>

<style scoped>

  .breadcrumb {
    padding: 0;
    background-color: transparent;
    margin: 20px 0;
  }

  .breadcrumb a {
    color: #495057;
  }

  .breadcrumb li,
  .breadcrumb li a {
    font-size: 13px;
  }

</style>
