<template>
  <div class="layout-notary">
    <main>
      <header-auth-component/>
      <breadcrumbs main-url="/"></breadcrumbs>
      <h1 v-if="$route.meta.title" :class="$route.meta.titleAlign?'align-'+$route.meta.titleAlign:''" >
        {{$route.meta.title}}
      </h1>
      <slot/>
    </main>
    <loader v-if="loader"/>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, watchEffect } from 'vue';
import { createToaster } from '@meforma/vue-toaster';
import Breadcrumbs from "../components/common/Breadcrumbs";
import Loader from "../components/common/Loader";
import HeaderAuthComponent from "../components/common/headerAuth/HeaderAuthComponent";

export default {
    components: {
        Breadcrumbs,
        Loader,
        HeaderAuthComponent
    },
    setup() {

        const store = useStore();
        const user = computed(() => store.state.auth.user);
        const toaster = createToaster({ position: 'top', duration: 10000 });

        watchEffect(() => {
            if(user.value && user.value.loggedIn === 0) {
                toaster.show('<b>Подсказка.</b><span>В разделе <b>Настройки</b> (иконка в виде "шестеренки", справа) вы сможете выбрать на какие адреса электронной почты в адрес Вашей нотариальной конторы будут отправлять наследники готовые отчеты об оценке.<br/>Также в разделе имеются информационные материалы для нотариальных контор - объявления, визитки.</span><a class="c-toast-close-btn" href="#">x</a>');
                user.value.loggedIn = 1;
            }
        });

        return {
            loader: computed(() => store.state.loader),
            user
        }
    }
}
</script>

<style>

  .layout-notary {
    height: 100%;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }

  .layout-notary h1 {
    font-size: 21px;
    color: #363636;
    margin-bottom: 20px;
  }

  .layout-notary h2 {
    font-size: 21px;
    color: #363636;
    margin-bottom: 20px;
  }

  main {
    height: 100%;
  }

  .c-toast-container  {
    position: fixed;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2em;
    overflow: hidden;
    z-index: 9999;
    pointer-events: none;
    width: 100%;
    text-align: center;
  }

  .c-toast.c-toast--default {
    display: grid;
    align-items: center;
    animation-duration: 150ms;
    margin: 0.5em 0;
    border-radius: 0.5em;
    pointer-events: auto;
    color: #000000;
    min-height: 3em;
    cursor: pointer;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    padding: 2.5em 1em;
    word-break: break-word;
    font-size: 28px;
    text-align: justify;
    line-height: 1.2em;
    background-color: #bde6e9e0;
    width: 40%;
    position: relative;
  }

  .c-toast .c-toast-close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #000000;
    text-decoration: none;
    font-weight: bold;
  }

  .c-toast.c-toast--default b:first-child {
    padding-bottom: 15px;
  }

</style>
