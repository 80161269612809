<template>
  <layout-default>
    <section v-if="isTokenVerified">
      <template v-if="!isComplete">
        <h1>Создание пароля</h1>
        <form @submit="handleCreatePasswordSubmit">
          <div class="form-group">
            <input type="password" class="form-control" placeholder="Пароль" v-model="fields.password.value" />
            <p class="error" v-if="fields.password.error !== ''">{{fields.password.error}}</p>
          </div>
          <div class="form-group">
            <input type="password" class="form-control" placeholder="Повтор пароля" v-model="fields.passwordRepeat.value" />
            <p class="error" v-if="fields.passwordRepeat.error !== ''">{{fields.passwordRepeat.error}}</p>
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-primary">Создать пароль</button>
          </div>
        </form>
      </template>
      <template v-else>
        <p class="success">Пароль был успешно создан.<br/>Вы можете <router-link to="/auth/sign-in">использовать</router-link> логин и пароль для входа</p>
      </template>
    </section>
  </layout-default>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { verifyPasswordResetTokenRequest, resetPasswordRequest } from "../../api/auth";
import { DEFAULT_SHOW_LOADER, DEFAULT_HIDE_LOADER } from "../../store/constants";
import LayoutDefault from "../../layouts/LayoutDefault";

export default {
    name: "ResetPasswordView",
    components: {
        LayoutDefault
    },
    setup() {

        const store = useStore();
        const route = useRoute();
        const token = route.query.token;
        const isTokenVerified = ref(false);
        const fields = ref({
            password: { value: '', error: '' },
            passwordRepeat: { value: '', error: '' }
        });
        const isComplete = ref(false);

        const handleCreatePasswordSubmit = (e) => {
            e.preventDefault();
            fields.value.password.error = '';
            fields.value.passwordRepeat.error = '';
            store.commit(DEFAULT_SHOW_LOADER);
            resetPasswordRequest(token, {
                password: fields.value.password.value,
                passwordRepeat: fields.value.passwordRepeat.value
            }).then(response => {
                store.commit(DEFAULT_HIDE_LOADER);
                const data = response.data;
                if(data) {
                    if (data.errorValidate) {
                        const errorValidate = data.errorValidate;
                        if(errorValidate['createpasswordform-password']) {
                            fields.value.password.error = errorValidate['createpasswordform-password'][0];
                        }
                        if(errorValidate['createpasswordform-passwordrepeat']) {
                            fields.value.passwordRepeat.error = errorValidate['createpasswordform-passwordrepeat'][0];
                        }
                    } else {
                        isComplete.value = true;
                    }
                }
            }).catch(err => console.log(err));
        };

        onMounted(() => {
            if(token) {
                store.commit(DEFAULT_SHOW_LOADER);
                verifyPasswordResetTokenRequest(token).then(response => {
                    store.commit(DEFAULT_HIDE_LOADER);
                    const data = response.data;
                    if(data === true) {
                        isTokenVerified.value = true;
                    }
                }).catch(err => console.log(err));
            }
        });

        return {
            isTokenVerified,
            fields,
            isComplete,
            handleCreatePasswordSubmit
        }
    }
}
</script>

<style scoped>

  section {
    max-width: 360px;
    margin: 0 auto;
  }

  section .success {
    color: #004000;
    background-color: #b0ffb3;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #299d29;
  }

</style>
