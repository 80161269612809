<template>
  <div class="layout-notary">
    <main>
      <header-component/>
      <slot/>
    </main>
    <loader v-if="loader"/>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import Breadcrumbs from "../components/common/Breadcrumbs";
import Loader from "../components/common/Loader";
import HeaderComponent from "../components/common/HeaderComponent";
import HeaderAuthComponent from "../components/common/headerAuth/HeaderAuthComponent";

export default {
    components: {
        Breadcrumbs,
        Loader,
        HeaderComponent
    },
    setup() {
        const store = useStore();
        return {
            loader: computed(() => store.state.loader)
        }
    }
}
</script>

<style>

  .layout-notary {
    height: 100%;
    padding: 20px;
  }

  .layout-notary h2 {
    font-size: 21px;
    color: #363636;
    margin-bottom: 20px;
  }

  main {
    height: 100%;
  }

</style>
