<template>
  <tr>
    <td>{{ index+1 }}</td>
    <td>{{ item.email }}</td>
    <td class="checkbox">
      <input
        type="checkbox"
        :disabled="disabled"
        v-model="item.send_notify"
        :true-value="1"
        :false-value="0"
        @change="handleChangeSendNotify(item)"
      />
    </td>
  </tr>
</template>

<script>
import { inject } from 'vue';
import { changeEmailSendNotifyRequest } from "../../../../api/notary";

export default {
    name: "ItemComponent",
    props: {
        index: {
            type: Number,
            required: true
        },
        item: {
            type: Object,
            required: true
        },
        disabled: {
            type: Boolean,
            required: true,
            default: false
        },
    },
    setup() {

        const checkCountWithSendNotify = inject('checkCountWithSendNotify');

        const handleChangeSendNotify = (item) => {
            checkCountWithSendNotify();
            changeEmailSendNotifyRequest({
                id: item.id,
                value: item.send_notify
            });
        };

        return {
            handleChangeSendNotify
        }
    }
}
</script>

<style scoped>

  .items th,
  .items td {
    border: 1px solid #afafaf;
    padding: 5px;
  }

  .items .checkbox {
    text-align: center;
  }

</style>
