import { postRequest, getRequest } from './axios';
import {
  AUTH_GET_USER_URL,
  AUTH_LOGOUT_URL,
  AUTH_FIND_NOTARY_URL,
  AUTH_FIND_NOTARY_EMAILS_URL,
  AUTH_SIGN_UP_URL,
  AUTH_VERIFY_EMAIL_TOKEN_URL,
  AUTH_CREATE_PASSWORD_URL,
  AUTH_SIGN_IN_URL,
  AUTH_LOST_PASS_URL,
  AUTH_VERIFY_PASSWORD_RESET_TOKEN_URL,
  AUTH_RESET_PASSWORD_URL
} from "./constants";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export function getUserRequest() {
  return getRequest(AUTH_GET_USER_URL, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

export function logoutRequest() {
  return getRequest(AUTH_LOGOUT_URL, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

export function findNotaryRequest(formData) {
  return postRequest(AUTH_FIND_NOTARY_URL, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    }
  });
}

export function findNotaryEmailsRequest(id) {
  return getRequest(AUTH_FIND_NOTARY_EMAILS_URL + '?id=' + id, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

export function signUpNotaryRequest(id, formData) {
  return postRequest(AUTH_SIGN_UP_URL + '?id=' + id, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    }
  });
}

export function verifyEmailTokenRequest(token) {
  return getRequest(AUTH_VERIFY_EMAIL_TOKEN_URL + '?token=' + token, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

export function createPasswordRequest(token, formData) {
  return postRequest(AUTH_CREATE_PASSWORD_URL + '?token=' + token, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

export function loginRequest(formData) {
  return postRequest(AUTH_SIGN_IN_URL, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

export function lostPassRequest(formData) {
  return postRequest(AUTH_LOST_PASS_URL, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

export function verifyPasswordResetTokenRequest(token) {
  return getRequest(AUTH_VERIFY_PASSWORD_RESET_TOKEN_URL + '?token=' + token, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

export function resetPasswordRequest(token, formData) {
  return postRequest(AUTH_RESET_PASSWORD_URL + '?token=' + token, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}
