import { postRequest, getRequest } from './axios';
import {
  NOTARY_GET_EMAILS_URL,
  NOTARY_CHANGE_EMAIL_NOTIFY_URL,
  NOTARY_ADD_EMAIL_URL,
  NOTARY_GET_ORDERS_URL,
  NOTARY_GET_FILE_URL,
  NOTARY_GET_ARCHIVE_URL,
  NOTARY_SETTING_GET_DATA_URL,
  NOTARY_SETTING_GET_A4_PDF_URL,
  NOTARY_SETTING_GET_A5_PDF_URL,
  NOTARY_SETTING_GET_CARD_VISITS_URL
} from "./constants";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export function getEmailsRequest() {
  return getRequest(NOTARY_GET_EMAILS_URL, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

export function changeEmailSendNotifyRequest(formData) {
  return postRequest(NOTARY_CHANGE_EMAIL_NOTIFY_URL, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

export function addEmailRequest(formData) {
  return postRequest(NOTARY_ADD_EMAIL_URL, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

export function getOrdersRequest(formData) {
  return postRequest(NOTARY_GET_ORDERS_URL, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

export function getFileRequest(id, formData) {
  return postRequest(NOTARY_GET_FILE_URL + '?id=' + id, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    },
    responseType: 'blob'
  });
}

export function getArchiveRequest(id, formData) {
  return postRequest(NOTARY_GET_ARCHIVE_URL + '?id=' + id, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    },
    responseType: 'blob'
  });
}

export function getSettingDataRequest() {
  return getRequest(NOTARY_SETTING_GET_DATA_URL, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    }
  });
}

export function getA4PdfRequest() {
  return getRequest(NOTARY_SETTING_GET_A4_PDF_URL, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    },
    responseType: 'blob'
  });
}

export function getA5PdfRequest() {
  return getRequest(NOTARY_SETTING_GET_A5_PDF_URL, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    },
    responseType: 'blob'
  });
}

export function getCartVisitsRequest() {
  return getRequest(NOTARY_SETTING_GET_CARD_VISITS_URL, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    }
  });
}
