export const BASE_URL = `${process.env.VUE_APP_API_URL}`;

export const AUTH_GET_USER_URL = '/auth/get-user';
export const AUTH_REFRESH_TOKEN_URL = '/auth/refresh-token';
export const AUTH_LOGOUT_URL = '/auth/logout';
export const AUTH_FIND_NOTARY_URL = '/auth/find-notary';
export const AUTH_FIND_NOTARY_EMAILS_URL = '/auth/find-notary-emails';
export const AUTH_SIGN_UP_URL = '/auth/sign-up';
export const AUTH_VERIFY_EMAIL_TOKEN_URL = '/auth/verify-email-token';
export const AUTH_CREATE_PASSWORD_URL = '/auth/create-password';
export const AUTH_SIGN_IN_URL = '/auth/sign-in';
export const AUTH_LOST_PASS_URL = '/auth/lost-pass';
export const AUTH_VERIFY_PASSWORD_RESET_TOKEN_URL = '/auth/verify-password-reset-token';
export const AUTH_RESET_PASSWORD_URL = '/auth/reset-password';

export const NOTARY_GET_EMAILS_URL = '/email/get-all';
export const NOTARY_CHANGE_EMAIL_NOTIFY_URL = '/email/change-send-notify';
export const NOTARY_ADD_EMAIL_URL = '/email/add';
export const NOTARY_GET_ORDERS_URL = '/order/get-all';
export const NOTARY_GET_FILE_URL = '/order/get-file';
export const NOTARY_GET_ARCHIVE_URL = '/order/get-archive';
export const NOTARY_SETTING_GET_DATA_URL = '/setting/get-data';
export const NOTARY_SETTING_GET_A4_PDF_URL = '/setting/get-a4-pdf';
export const NOTARY_SETTING_GET_A5_PDF_URL = '/setting/get-a5-pdf';
export const NOTARY_SETTING_GET_CARD_VISITS_URL = '/setting/get-card-visits';
