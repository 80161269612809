<template>
  <layout-auth v-if="user">
    <top-component/>
    <orders-component/>
  </layout-auth>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import LayoutAuth from "../../layouts/LayoutAuth";
import TopComponent from "./top/TopComponent";
import OrdersComponent from "./orders/OrdersComponent";
import HeaderAuthComponent from "../../components/common/headerAuth/HeaderAuthComponent";
export default {
    name: "HomeView",
    components: {
        LayoutAuth,
        TopComponent,
        OrdersComponent,
        HeaderAuthComponent
    },
    setup() {

        const store = useStore();

        return {
            user: computed(() => store.state.auth.user)
        }
    }
}
</script>

<style scoped>

</style>
