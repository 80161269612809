<template>
  <layout-default>
    <section class="page-section">
      <template v-if="!isComplete">
        <h2>Найдите себя в реестре нотариусов и выберите пароль для входа</h2>
        <div class="form">
          <search-component />
          <email-component v-if="fields.id" :id="fields.id" />
          <div class="actions">
            <button type="button" class="btn btn-primary" :disabled="!isButtonActive" @click="handleSignUpSubmit" >
              Продолжить регистрацию
            </button>
            <ul>
              <li><router-link to="/auth/sign-in">Вернуться ко входу</router-link></li>
            </ul>
          </div>
        </div>
      </template>
      <div v-else class="result-notify">
        <p v-if="isWithCustomEmail === false" class="success">На выбранный адрес электронной почты отправлено письмо с запросом на подтверждение регистрации и дальнейшими инструкциями. Если письмо не поступит в течение 5 минут, проверьте папку СПАМ или свяжитесь с нами по тел +7 (812) 425-30-30.</p>
        <div v-else-if="isWithCustomEmail === true" class="success">
          <h3>Запрос на подключение к архиву отчетов<br> сервиса НАСЛЕДСТВО<span>.ОНЛАЙН</span> принят</h3>
          <p>Мы свяжемся с нотариальной конторой (в рабочие часы) для подтверждения e-mail адреса: <span>{{fields.email.value}}</span>.</p>
          <p>В случае успешного подтверждения, на указанный e-mail поступит письмо со ссылкой для активации доступа к архиву отчетов и выбора пароля для входа.</p>
        </div>
      </div>
    </section>
    <loader v-if="loader" position="fixed"/>
  </layout-default>
</template>

<script>
import { ref, provide, watchEffect } from 'vue';
import { signUpNotaryRequest } from "../../../api/auth";
import LayoutDefault from "../../../layouts/LayoutDefault";
import SearchComponent from "./search/SearchComponent";
import EmailComponent from "./emails/EmailComponent";
import Loader from "../../../components/common/Loader";
export default {
  name: "SignUpView",
  components: {
      Loader,
      LayoutDefault,
      EmailComponent,
      SearchComponent
  },
  setup() {

      const isComplete = ref(false);
      const isButtonActive = ref(false);
      const isWithCustomEmail = ref(null);
      const fields = ref({
          id: null,
          name: '',
          email: { id: null, value: '', error: '' },
          region: ''
      });
      const loader = ref(false);

      const clearFields = () => {
          fields.value.id = null;
          fields.value.email.id = null;
          fields.value.email.value = '';
          fields.value.email.error = '';
      };

      const handleSignUpSubmit = () => {
          loader.value = true;
          fields.value.email.error = '';
          signUpNotaryRequest(fields.value.id, {
              emailId: fields.value.email.id,
              email: fields.value.email.value
          }).then(response => {
              loader.value = false;
              const data = response.data;
              if(data) {
                  if (data.errorValidate) {
                      const errorValidate = data.errorValidate;
                      if(errorValidate['signupform-email']) {
                          fields.value.email.error = errorValidate['signupform-email'][0];
                      }
                  } else {
                      if(!fields.value.email.id) {
                          isWithCustomEmail.value = true;
                      } else {
                          isWithCustomEmail.value = false;
                      }
                      isComplete.value = true;
                  }
              }
          }).catch(err => console.log(err));
      };

      watchEffect(() => {
          if(fields.value.id && fields.value.email.value) {
              isButtonActive.value = true;
          } else {
              isButtonActive.value = false;
          }
      });

      provide('fields', fields);
      provide('clearFields', clearFields);

      return {
          fields,
          loader,
          isButtonActive,
          handleSignUpSubmit,
          isComplete,
          isWithCustomEmail
      }
  }
}
</script>

<style scoped>

  .page-section {
    margin-top: 100px;
  }

  section h2 {
    text-align: center;
  }

  section>.form {
    max-width: 530px;
    margin: 0 auto;
  }

  section .success {
    color: #004000;
    background-color: #b0ffb3;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #299d29;
    font-size: 15px;
    line-height: 19px;
  }

  section .success>p {
    margin-bottom: 5px;
    font-size: 17px;
  }

  section .success>p:last-child {
    margin-bottom: 0;
  }

  section .success>p>span {
    font-weight: 500;
  }

  section .actions>ul,
  section .actions>button {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    margin-bottom: 0;
  }

  section .actions>ul li {
    margin-bottom: 2px;
  }

  section .result-notify {
    max-width: 1000px;
    margin: 0 auto;
  }

  section .result-notify .success h3 {
    text-align: center;
    font-size: 24px;
    padding-bottom: 20px;
    font-weight: 700;
    padding-top: 10px;
  }

  section .result-notify .success h3 span {
    color: #00B0F0;
  }

</style>
